<template>
  <div id="app">
    <div v-if="getLoadingtraduction">
      <div class="load">
        <!-- <div class="block_img">
          <img src="@/assets/Logo@2x.png" class="brand-logo" />
        </div> -->
        <b-spinner label="Large Spinner"></b-spinner>
      </div>
    </div>

    <div v-else>
      <div v-if="connected">
        <batigo-nav-bar></batigo-nav-bar>
      </div>
      <div v-if="getOnlineUser">
        <b-alert
          variant="warning"
          show
          dismissible
          v-if="!getOnlineUser.email_verified_at"
        >
          {{ $t("CONFIRM_EMAIL") }}<br />
          {{ $t("EMAIL_CONFIRMED") }}
        </b-alert>
      </div>

      <div class="sw-body">
        <batigo-side-bar v-if="connected && sidebar"></batigo-side-bar>
        <batigoSideBarClient
          v-if="connected && sidebarClient"
        ></batigoSideBarClient>
        <router-view v-if="!getLoadingtraduction" />
      </div>
      <footer v-if="!isDevis">
        <div class="link">
          <router-link to="/about_us">{{ $t("ABOUT_US") }}</router-link>
          <router-link to="/privacy">{{ $t("LEGAL_MENTION") }}</router-link>
          <router-link to="/cgu">{{ $t("C.G.U") }}</router-link>
        </div>
        <p>{{ $t("COPYRIGHT") }}</p>
      </footer>
    </div>
    <b-modal
      ref="modal-center"
      id="modal-center"
      centered
      title="Email Verify"
      hide-footer
      hide-header
    >
      <p class="my-4" v-if="getOnlineUser">{{ $t("EMAIL_CONFIRM") }}</p>
      <p class="my-4" v-else>{{ messages.login.confirm }}</p>
    </b-modal>
  </div>
</template>

<script src="https://js.pusher.com/7.1/pusher.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/vue/dist/vue.js"></script>
<script>
import { mapGetters, mapActions } from "vuex";
import batigoNavBar from "@/components/navbar";
import batigoSideBar from "@/components/sidebar";
import batigoSideBarClient from "@/components/sideClient";
import FR from "@/langues/fr.json";
import Pusher from "pusher-js";

export default {
  components: {
    batigoNavBar,
    batigoSideBar,
    batigoSideBarClient,
  },
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      messages: FR,
      text: [],
    };
  },
  methods: {
    ...mapActions([
      "allActiveLangues",
      "all_notifs",
      "set_notif",
      "allLangue",
      "dictionnaire",
      "saveLang",
      "changeLang",
      "translation",
    ]),
    hideModal(ref) {
      this.$refs[ref].hide();
    },
  },
  computed: {
    ...mapGetters([
      "getToken",
      "getLoadingtraduction",
      "getOnlineUser",
      "getErreur",
      "getShowErreur",
      "isEmailVerif",
      "getNotifs",
      "getLoadingdictionnaire",
      "getSelectedLang",
      "getActiveLangue",
    ]),
    connected() {
      if (this.$route.name != "Sign" && this.getToken) {
        return true;
      }
      return false;
    },
    sidebar() {
      if (this.$route.meta.sidebar) {
        return true;
      }
      return false;
    },
    isDevis() {
      return this.$route.name == "Devis"|| "Devis Types";
    },
    sidebarClient() {
      if (this.$route.meta.sidebarClient) {
        return true;
      }
      return false;
    },
  },

  mounted() {
    var pusher = new Pusher("878a345986e0ae5b5dc9", {
      cluster: "eu",
    });
    if (this.getOnlineUser) {
      const channel = pusher.subscribe("user-" + this.getOnlineUser.id);
      channel.bind("notification", (data) => {
        this.set_notif(data);
        this.all_notifs();
      });
    }

    if (this.isEmailVerif) {
      this.$refs["modal-center"].show();
    }

    this.allActiveLangues({
      page: 1,
      per_page: 100,
    });

    if (this.getOnlineUser) {
      this.saveLang(this.getSelectedLang);
    } else {
      this.changeLang(this.getSelectedLang);
    }
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");

#app {
  .sw-body {
    // display: flex;
    // height: calc(100vh - 54px);
    height: 100%;
    background-color: #f5f6f8;
  }
}

.alert-warning {
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  z-index: 1000;
  text-align: center;
}

.load {
  // width: 300px;
  // height: 200px;
  position: fixed;
  background-color: #28367a;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner-border {
    width: 3.5rem;
    height: 3.5rem;
  }

  .block_img {
    background-color: #fff;
    height: 250px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}
footer {
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
  border-top: 8px solid #e4261b;
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  background-color: #28367a;
  height: 56px;
  color: #fff;
  .link {
    @media only screen and (max-width: 900px) {
      width: 100%;
      justify-content: space-around;
    }
    width: 35%;
    display: flex;
    justify-content: space-between;

    & a {
      font-size: 14px;
      text-decoration: underline;
      color: #fff;

      &:hover {
        color: #ffffff80;
      }
    }
  }

  & p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
  }
}
</style>
