import domain from "@/environment";
import Mainoeuvre from "../../models/mainoeuvre";
import axios from "axios";

const state = {
  mainoeuvre: null,
  mainoeuvres: [],
  mainoeuvreLoading: false,
  mainoeuvreRows: 0,
};
const getters = {
  getmainoeuvre: (state) => state.mainoeuvre,
  getAllmainoeuvres: (state) => state.mainoeuvres,
  getmainoeuvreLoading: (state) => state.mainoeuvreLoading,
  getTotalRowmainoeuvre: (state) => state.mainoeuvreRows,
};
const mutations = {
  DO_NOTHING() {},
  SET_mainoeuvre(state, payload) {
    if (payload) {
      state.mainoeuvre = Mainoeuvre.create(payload);
    } else {
      state.mainoeuvre = null;
    }
  },

  SET_mainoeuvreS(state, payload) {
    if (payload) {
      state.mainoeuvres = payload.map((item) => Mainoeuvre.create(item));
    } else {
      state.mainoeuvres = [];
    }
  },
  SET_mainoeuvreLOADING(state, payload) {
    if (payload) {
      state.mainoeuvreLoading = payload;
    } else {
      state.mainoeuvreLoading = false;
    }
  },
  PUSH_mainoeuvre(state, payload) {
    state.mainoeuvres.push(Mainoeuvre.create(payload));
  },
  UPDATE_mainoeuvre(state, payload) {
    for (let index = 0; index < state.mainoeuvres.length; index++) {
      if (state.mainoeuvres[index].id == payload.id) {
        state.mainoeuvres[index].update(payload);
        break;
      }
    }
  },
  DELETE_mainoeuvre(state, payload) {
    for (let index = 0; index < state.mainoeuvres.length; index++) {
      if (state.mainoeuvres[index].id == payload.id) {
        state.mainoeuvres.splice(index, 1);
        break;
      }
    }
  },
  UPDATE_mainoeuvre_ROW(state, payload) {
    if (payload) {
      state.mainoeuvreRows = payload;
    } else {
      state.mainoeuvreRows = 0;
    }
  },
};

const actions = {
  async all_mainoeuvres({ commit }, payload) {
    commit("SET_mainoeuvreS");

    const params = {
      page: payload.page,
      per_page: payload.per_page,
      entreprise_id: payload.entreprise,
      search: payload.search,
      default: payload.default,
      with_default: payload.with_default,
    };
    try {
      const responce = await axios.get(domain + `/main-oeuvre`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_mainoeuvreS", responce.data.data.data);
      commit("UPDATE_mainoeuvre_ROW", responce.data.data.total);

      return responce.data;
    } catch (error) {
 
      throw error.response.data.message;
    }
  },

  async store_mainoeuvre({ commit }, payload) {
    commit("SET_mainoeuvreLOADING", true);
    const params = {
      lib: payload.lib,
      ref: payload.ref,
      desc: payload.desc,
      type_unite: payload.type_unite,
      type_cout:payload.type_cout,
      information:payload.information||0,
      quantite: payload.quantite,
      unit_price: payload.unit_price,
      tva: payload.tva,
      favoris: payload.favoris ? "true" : "false",
    };
    try {
      const responce = await axios.post(`${domain}/main-oeuvre`, null, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
          "Access-Control-Allow-Origin": " *",
        },
        params,
      });

      commit("PUSH_mainoeuvre", responce.data.data);
      commit("UPDATE_mainoeuvre_ROW", state.mainoeuvreRows + 1);
      commit("SET_mainoeuvreLOADING", false);
      return Mainoeuvre.create(responce.data.data);
    } catch (error) {
      commit("SET_mainoeuvreLOADING", false);
      throw error.response.data.message;
    }
  },
  async store_mainoeuvreJSON({ commit }, payload) {
    commit("SET_mainoeuvreLOADING", true);
    const params = {
      lib: payload.lib,
      ref: payload.ref,
      desc: payload.desc,
      type_unite: payload.type_unite,
      type_cout:payload.type_cout,
      information:payload.information||0,
      quantite: payload.quantite,
      unit_price: payload.unit_price,
      tva: payload.tva,
      favoris: payload.favoris ? "true" : "false",
    };
    try {
      const responce = await axios.post(`${domain}/main-oeuvre`, null, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
       
        },
        params,
      });

      commit("PUSH_mainoeuvre", responce.data.data);
      commit("UPDATE_mainoeuvre_ROW", state.mainoeuvreRows + 1);
      commit("SET_mainoeuvreLOADING", false);
      return Mainoeuvre.create(responce.data.data);
    } catch (error) {
      commit("SET_mainoeuvreLOADING", false);
   
      return null
    }
  },
  async edit_mainoeuvre({ commit }, payload) {
    commit("SET_mainoeuvreLOADING", true);
    var params = {
      id: payload.id,
      lib: payload.lib,
      ref: payload.ref,
      desc: payload.desc,
      type_unite: payload.type_unite,
      quantite: payload.quantite,
      unit_price: payload.unit_price,
      tva: payload.tva,
      favoris: payload.favoris ? 1 : 0,
    };
    try {
      const responce = await axios.put(
        `${domain}/main-oeuvre/${payload.id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": " *",
          },
          params,
        }
      );

      commit("UPDATE_mainoeuvre", responce.data.data);
      commit("SET_mainoeuvreLOADING", false);
      return responce.data;
    } catch (error) {
      commit("SET_mainoeuvreLOADING", false);
      throw error.response.data.message;
    }
  },
  async delete_mainoeuvre({ commit }, payload) {
    commit("DO_NOTHING");
    commit("SET_mainoeuvreLOADING", true);
    try {
      await axios.delete(domain + `/main-oeuvre/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_mainoeuvre", payload);
      commit("UPDATE_mainoeuvre_ROW", state.mainoeuvreRows - 1);
      commit("SET_mainoeuvreLOADING", false);
      return true;
    } catch (error) {
      commit("SET_mainoeuvreLOADING", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
